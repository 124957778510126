@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 400;
  src:
    url("fonts/zilla-slab-v11-latin-regular.woff2") format("woff2"),
    url("fonts/zilla-slab-v11-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 700;
  src:
    url("fonts/zilla-slab-v11-latin-700.woff2") format("woff2"),
    url("fonts/zilla-slab-v11-latin-700.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/inter-v13-latin-regular.woff2") format("woff2");
}

:root {
  --bg-color: #121212;
  --card-color: #2222224a;
  --card-shadow: #000a;
  --card-hover-shadow: #000;
  --text-color: #fff;
  --card-hover-color: var(--text-color);
  --underline-color: #1f71ff;
}

html {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "Zilla Slab", serif;
  font-weight: 700;
  margin: 0;
}

body {
  margin: 0;
}

@media only screen and (max-width: 900px) {
  html {
    font-size: large;
  }
}

#main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("beams.webp");
  background-position: center;
}

#main-content main {
  max-width: 80vw;
}

.card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 3rem;
}

span.gray,
h4.gray {
  color: #9b9b9b;
}

h2 {
  font-size: 2rem;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.projects {
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(3, 1fr);
}

@media (width <= 1200px) {
  .projects {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 750px) {
  .projects {
    grid-template-columns: 1fr;
  }
}

.projects > a {
  display: flex;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

.ps-card {
  margin: var(--card-margin, 1.25rem);
  min-width: 8rem;
  max-width: 17rem;
  padding: 1rem 1.5rem;
  border-radius: 20px;
  background-color: var(--card-color);
  box-shadow:
    var(--card-shadow) 0 0 10px 0,
    #fff4 inset 0 0 3px 0;
  transition:
    transform 100ms,
    color 200ms,
    box-shadow 100ms;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(30px);
}

.ps-card:hover,
a:focus-visible .ps-card {
  color: var(--card-hover-color);
  fill: var(--card-hover-color);
}

.ps-card svg {
  width: 4rem;
  height: 4rem;
}

.ps-card h3 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1.5rem;
}
.ps-card h4 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ps-card span {
  font-weight: 400;
  text-align: justify;
  font-family: "Inter", sans-serif;
}

.card-list article:hover,
.card-list a:focus-visible article {
  box-shadow:
    var(--card-hover-shadow) 0 0 20px 0,
    #fff3 inset 0 0 4px 0;
  transform: scale(1.1);
}

a:focus-visible {
  outline: none;
}

.underline::after {
  content: "";
  z-index: -1;
  position: relative;
  display: block;
  width: 100%;
  height: 0.1rem;
  background-color: var(--underline-color);
  transform: scaleX(0);
  transition: transform 150ms;
  transform-origin: right;
}

.underline:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.fly-in.underline::after {
  animation: 1s underline-fly-in cubic-bezier(0.805, 0, 0.07, 1);
}

@keyframes underline-fly-in {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@media only screen and (min-aspect-ratio: 4/5) {
  .fly-in {
    animation: 1s fly-in cubic-bezier(0.805, 0, 0.07, 1);
  }
}

@keyframes fly-in {
  0% {
    transform: scale(3) translateY(150%);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}

.fade-in {
  animation: 0.5s fade-in;
  animation-delay: 0.1s;
  animation-fill-mode: backwards;
}

@media only screen and (min-aspect-ratio: 4/5) {
  .fade-in {
    animation-delay: 0.6s;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

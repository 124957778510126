@font-face {
  font-family: Zilla Slab;
  font-style: normal;
  font-weight: 400;
  src: url("zilla-slab-v11-latin-regular.82d3973a.woff2") format("woff2"), url("zilla-slab-v11-latin-regular.a61fa72d.woff") format("woff");
}

@font-face {
  font-family: Zilla Slab;
  font-style: normal;
  font-weight: 700;
  src: url("zilla-slab-v11-latin-700.30002fd4.woff2") format("woff2"), url("zilla-slab-v11-latin-700.9c239608.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url("inter-v13-latin-regular.8f7fd453.woff2") format("woff2");
}

:root {
  --bg-color: #121212;
  --card-color: #2222224a;
  --card-shadow: #000a;
  --card-hover-shadow: #000;
  --text-color: #fff;
  --card-hover-color: var(--text-color);
  --underline-color: #1f71ff;
}

html {
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  font-family: Zilla Slab, serif;
  font-weight: 700;
}

body {
  margin: 0;
}

@media only screen and (width <= 900px) {
  html {
    font-size: large;
  }
}

#main-content {
  background-image: url("beams.c2af3485.webp");
  background-position: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#main-content main {
  max-width: 80vw;
}

.card-section {
  flex-direction: column;
  align-items: center;
  display: flex;
}

h1 {
  font-size: 3rem;
}

span.gray, h4.gray {
  color: #9b9b9b;
}

h2 {
  font-size: 2rem;
}

.card-list {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.projects {
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  display: grid;
}

@media (width <= 1200px) {
  .projects {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 750px) {
  .projects {
    grid-template-columns: 1fr;
  }
}

.projects > a {
  display: flex;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

.ps-card {
  margin: var(--card-margin, 1.25rem);
  background-color: var(--card-color);
  min-width: 8rem;
  max-width: 17rem;
  box-shadow: var(--card-shadow) 0 0 10px 0, #fff4 inset 0 0 3px 0;
  color: var(--text-color);
  backdrop-filter: blur(30px);
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
  transition: transform .1s, color .2s, box-shadow .1s;
  display: flex;
}

.ps-card:hover, a:focus-visible .ps-card {
  color: var(--card-hover-color);
  fill: var(--card-hover-color);
}

.ps-card svg {
  width: 4rem;
  height: 4rem;
}

.ps-card h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.ps-card h4 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.ps-card span {
  text-align: justify;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.card-list article:hover, .card-list a:focus-visible article {
  box-shadow: var(--card-hover-shadow) 0 0 20px 0, #fff3 inset 0 0 4px 0;
  transform: scale(1.1);
}

a:focus-visible {
  outline: none;
}

.underline:after {
  content: "";
  z-index: -1;
  background-color: var(--underline-color);
  transform-origin: 100%;
  width: 100%;
  height: .1rem;
  transition: transform .15s;
  display: block;
  position: relative;
  transform: scaleX(0);
}

.underline:hover:after {
  transform-origin: 0;
  transform: scaleX(1);
}

.fly-in.underline:after {
  animation: 1s cubic-bezier(.805, 0, .07, 1) underline-fly-in;
}

@keyframes underline-fly-in {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

@media only screen and (aspect-ratio >= 4 / 5) {
  .fly-in {
    animation: 1s cubic-bezier(.805, 0, .07, 1) fly-in;
  }
}

@keyframes fly-in {
  0% {
    transform: scale(3)translateY(150%);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

.fade-in {
  animation: .5s .1s backwards fade-in;
}

@media only screen and (aspect-ratio >= 4 / 5) {
  .fade-in {
    animation-delay: .6s;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
/*# sourceMappingURL=repo.0efade1a.css.map */
